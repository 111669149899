<template>
  <div class="container">
    <!-- Attach the directive to the element you want to observe -->
    <div v-observe-visibility="handleVisibility" class="watched-component">
      <h4>{{ formattedCount }}+</h4>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    target: {
      type: Number,
      required: true,
    },
    duration: {
      type: Number,
      default: 500, // Duration in milliseconds
    },
  },
  data() {
    return {
      count: 0,
    };
  },
  computed: {
    formattedCount() {
      // Format the number with commas for better readability
      return this.count.toLocaleString();
    },
  },
  methods: {
    handleVisibility(isVisible) {
      if (isVisible) {
        this.startCount();
      }
    },
    startCount() {
      const startTime = performance.now();
      const animate = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        if (elapsedTime < this.duration) {
          // Calculate the current count based on the elapsed time
          this.count = Math.round((this.target * elapsedTime) / this.duration);
          requestAnimationFrame(animate);
        } else {
          // Ensure the final count is exactly the target
          this.count = this.target;
        }
      };
      requestAnimationFrame(animate);
    },
  },
};
</script>
