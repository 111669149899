<template>
  <div id="about" class="main-body">
    <div id="history" class="fadeIn">
      <h1 class="title-md text-darkblue">
        Journey Through Golog's
        <span class="text-primary">Thriving History</span>
      </h1>
      <h4>Hover down below to learn our history</h4>
      <div id="timeline">
        <div class="ruler">
          <div
            v-for="(t, index) in timeline"
            :key="index"
            class="main-container"
          >
            <div class="inch">
              <div class="year-container">
                <div class="year-label select-year">
                  {{ t.year }}
                </div>
                <div>
                  <div class="ruler-main" />
                </div>
                <div
                  class="year-detail"
                  :class="
                    index === 0
                      ? 'first'
                      : index === timelineLastIndex
                      ? 'last'
                      : 'default'
                  "
                >
                  <div id="image" class="select-year">
                    <img :src="t.picture" />
                  </div>
                  <div id="description">
                    <h4>
                      {{ t.title }}
                    </h4>
                    <p>{{ t.description }}</p>
                  </div>
                </div>
              </div>
              <div v-show="index != 5 - 1" class="lines-container">
                <div v-for="n in lineNumbers" :key="n" class="space-line" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="discover" class="row fadeIn">
      <h1 class="title-md text-darkblue mt-3 col-12 col-lg-7">
        Discover the <span class="text-primary">Legacy</span> <br />
        Behind Our <span class="text-primary">Excellence</span>
      </h1>

      <div class="mt-3 col-12 col-lg-5">
        <p class="text-darkblue">
          Golog was founded in 2017 by Ivan Chin and Tan. Since its inception,
          the company has focused on transforming the logistics industry,
          particularly in cold chain solutions, by leveraging advanced
          technology and a commitment to quality service
        </p>
        <div class="d-flex align-items-start">
          <img src="@/assets/images/icons/main/mission.svg" alt="" width="30" />
          <div class="ml-2">
            <h3>Mission</h3>
            <p>
              To provide innovative, efficient, and sustainable logistics
              solutions that empower businesses to deliver high-quality products
              with integrity and reliability. We strive to be the most trusted
              partner in cold chain logistics, combining advanced technology,
              exceptional customer service, and a commitment to quality.
            </p>
          </div>
        </div>
        <div class="d-flex align-items-start">
          <img src="@/assets/images/icons/main/vision.svg" width="30" />
          <div class="ml-2">
            <h3>Vision</h3>
            <p>
              To be a global leader in intelligent cold chain logistics, setting
              the standard for excellence and innovation. By prioritizing
              sustainability and customer satisfaction, we aim to redefine
              logistics, creating a seamless supply chain that supports business
              growth and ensures product integrity from origin to destination.
            </p>
          </div>
        </div>
      </div>
    </div>

    <b-row id="review" align-h="center fadeIn">
      <div class="item col-6 col-md-3">
        <img src="@/assets/images/icons/main/order.svg" />
        <CounterRating :target="1000000" :duration="2000" />
        <h6>Order sent</h6>
      </div>

      <div class="item col-6 col-md-3">
        <img src="@/assets/images/icons/main/smile.svg" />
        <CounterRating :target="500" :duration="2000" />
        <h6>Happy customer</h6>
      </div>

      <div class="item col-6 col-md-3">
        <img src="@/assets/images/icons/main/lorry.svg" />
        <CounterRating :target="210245" :duration="2000" />
        <h6>Kilometers covered</h6>
      </div>

      <div class="item col-6 col-md-3">
        <img src="@/assets/images/icons/main/star.svg" />
        <CounterRating :target="4.7" :duration="2000" />
        <h6>Ratings on Google Review</h6>
      </div>
    </b-row>

    <div id="different">
      <h1 class="title-md text-center text-darkblue fadeIn">
        We are <span class="text-primary">different</span>
      </h1>

      <b-row align-h="center" class="different-box-container">
        <b-row align-h="center">
          <div class="d-flex justify-content-center col-6 col-lg-3 mt-5">
            <div id="i1" class="item">
              <img src="@/assets/images/main/globe.png" />
              <h4>Specialized Cold Chain Expertise</h4>
              <p class="text-wrap">
                Unlike other logistics providers, our expertise is deeply rooted
                in intelligent cold chain solutions. We don’t just transport
                goods; we ensure they’re kept at ideal temperatures,
                safeguarding quality at every step.
              </p>
            </div>
          </div>

          <div class="d-flex justify-content-center col-6 col-lg-3 mt-5">
            <div id="i2" class="item">
              <img src="@/assets/images/main/boxman.png" />
              <h4>Customer-Centric Flexibility</h4>
              <p class="text-wrap">
                We understand that every business is unique. That’s why we
                provide tailored logistics solutions designed around each
                client’s specific needs, offering flexibility that larger,
                traditional logistics firms simply can’t match.
              </p>
            </div>
          </div>

          <div class="d-flex justify-content-center col-6 col-lg-3 mt-5">
            <div id="i3" class="item">
              <img src="@/assets/images/main/technology.png" />
              <h4>Advanced Technology and Transparency</h4>
              <p class="text-wrap">
                With real-time tracking and precise monitoring systems, we
                deliver end-to-end visibility so clients are always informed and
                in control of their supply chain.
              </p>
            </div>
          </div>

          <div class="d-flex justify-content-center col-6 col-lg-3 mt-5">
            <div id="i4" class="item">
              <img src="@/assets/images/main/commitment.png" />
              <h4>Commitment to Sustainability</h4>
              <p class="text-wrap">
                Golog integrates sustainable practices throughout its
                operations, from route optimization to eco-friendly packaging,
                aligning our values with those of environmentally conscious
                businesses.
              </p>
            </div>
          </div>
        </b-row>
      </b-row>
    </div>

    <QuestionContent class="fadeIn" />
  </div>
</template>
<script>
import QuestionContent from "@/components/QuestionContent.vue";
import CounterRating from "@/components/CounterRating.vue";
import intersectionObserverMixin from "@/mixins/intersectionObserverMixin";

export default {
  components: { QuestionContent, CounterRating },
  mixins: [intersectionObserverMixin],
  data() {
    return {
      lines: 12,
      timeline: [
        {
          year: 2017,
          picture: require("@/assets/images/main/timeline/2017.jpg"),
          title: "BULIDING THE FOUNDATION",
          description:
            "Golog was founded in 2017 to transform Malaysia’s cold chain logistics platform with smart, efficient supply chain solutions. In 2018, Golog launched its first delivery service, starting its journey as a logistic leader.",
        },
        {
          year: 2019,
          picture: require("@/assets/images/main/timeline/2019.jpg"),
          title: "LAYING THE GROUDWORK",
          description:
            "Although all these were quieter years, GOLOG focused on strengthening its internal systems in preparation for future milestones and participated in the 2019 Alibaba Cloud Startup Contest.",
        },
        {
          year: 2021,
          picture: require("@/assets/images/main/timeline/2021.jpg"),
          title: "EXPANDING HORIZONS",
          description:
            "This year, we reached a remarkable milestone in delivery volume, driven by our relentless commitment to innovation. With the integration of our B2B Pro, Smart Truck and Mini DC systems, we have set new standards in sustainable logistics. Our efforts were proudly recognized with the prestigious JCI Malaysia Sustainable Development Award, underscoring our dedication to a greener future in logistics.",
        },
        {
          year: 2023,
          picture: require("@/assets/images/main/timeline/2023.jpg"),
          title: "STRENGTHENING PARTNERSHIPS",
          description:
            "On January 13th, GOLOG, as a leading cold chain logistics platform, forged a strategic MOU with MYDIN, extending its specialized logistics capabilities. Strengthening its impact in Malaysia’s food supply chain, GOLOG further partnered with FAMA on November 6th, reinforcing its pivotal role in ensuring food security and accessibility across the nation",
        },
        {
          year: 2024,
          picture: require("@/assets/images/main/timeline/2024.jpg"),
          title: "MAJOR DEVELOPMENTS",
          description:
            "On 14th May, GOLOG signed an MOU with Royal Eficaz General Trading LLC, securing a USD 150 million investment to drive growth and innovation. Later, on November 23rd, GOLOG celebrated the groundbreaking of its Regional Hub Distribution Center (RHDC), a state-of-art facility that includes an Automated Storage Retrieval System (ASRS) and a certified Halal Hub, reinforcing its position as a leader in the industry.",
        },
      ],
      timelineLastIndex: 0,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    lineNumbers() {
      if (this.windowWidth < 768) {
        return 12;
      }
      if (this.windowWidth >= 768) {
        return 18;
      }
      if (this.windowWidth >= 992) {
        return 25;
      }
    },
  },
  mounted() {
    this.setupIntersectionObserver(".item", 0.2);
    this.setupIntersectionObserver(".fadeIn", 0.2);
    this.timelineLastIndex = this.timeline.length - 1;
    window.addEventListener("resize", this.updateWindowWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateWindowWidth);
  },
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>
